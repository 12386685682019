import PageTop from '@/components/PageTop';
import { LayoutProps } from '..';

export const DefaultPageLayout = ({ children, properties, image }: LayoutProps) => {
  return (
    <>
      <PageTop image={image} properties={properties} />
      <section>{children}</section>
    </>
  );
};

export default DefaultPageLayout;
