import { IApiMediaWithCropsResponseModel } from '@ingeniorforeningen/umbraco-client';
import DefaultPageLayout from './DefaultPageLayout';
import FrontPageLayout from './FrontPageLayout';
import SignupPageLayout from './SignupPageLayout';
import PersonInfoPageLayout from './PersonInfoPageLayout';
import SectionFrontPageLayout from './SectionFrontPageLayout';
import InfoPageLayout from './InfoPageLayout';

export type LayoutProps = {
  children: React.ReactNode;
  properties?: Record<string, any>;
  image?: IApiMediaWithCropsResponseModel;
};

export const layoutMap: {
  [key: string]: React.ComponentType<{ children: React.ReactNode; properties?: any }> | undefined;
} = {
  default: DefaultPageLayout,
  conferences_signupPage: SignupPageLayout,
  conferences_infoPage: InfoPageLayout,
  conferences_frontpage: FrontPageLayout,
  conferences_person_infoPage: PersonInfoPageLayout,
  conference_sectionFrontpage: SectionFrontPageLayout,
  // other mappings...
};
