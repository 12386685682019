import PageTop from '@/components/PageTop';
import SignUpCta from '@/components/SignUpCta';
import React from 'react';
import { LayoutProps } from '.';
import { Group } from '@ingeniorforeningen/figurine-core';

const SignupPageLayout = ({ children, properties, image }: LayoutProps) => {
  return (
    <>
      <PageTop image={image} properties={properties} />
      <section>
        <Group justify="center" visibleFrom="sm">
          <SignUpCta {...properties} />
        </Group>
        {children}
        <SignUpCta {...properties} />
      </section>
    </>
  );
};

export default SignupPageLayout;
