import {
  Button,
  Floater,
  Grid,
  Heading,
  Modal,
  Paper,
  Paragraph,
  showNotification,
} from '@ingeniorforeningen/figurine-core';
import { useRouter } from 'next/router';
import { SyntheticEvent, useEffect, useState } from 'react';

const Preview = () => {
  const { locale } = useRouter();
  const [guid, setGuid] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tag = 'data-guid';
      setGuid(window.document.querySelector(`meta[${tag}]`)?.getAttribute(tag) as string);
    }
  }, []);

  const copyPreviewToClipBoard = (event: SyntheticEvent<Element, Event>) => {
    if (typeof window !== 'undefined') {
      event.preventDefault();
      const previewUrl = `${window?.location?.origin}/api/preview?id=${guid}&locale=${locale}`;
      navigator.clipboard.writeText(previewUrl);
      showNotification({
        text: 'Side er gemt til udklipsholder',
        type: 'success',
      });
    }
  };

  const PreviewActions = (
    <>
      <Heading type="h3" text="Preview" />
      <Paragraph text="Du får vist upubliseret indhold, som ikke er tilgængeligt på sitet." />
      <Grid container>
        <Button
          href={`${process.env.NEXT_PUBLIC_UMBRACO_API_URL}/umbraco#/content/content/edit/${guid}`}
          icon="Edit"
          text="Rediger side"
          target="_blank"
        />
        <Button onClick={copyPreviewToClipBoard} text="Del side" icon="Send" />
        <Button href={`/api/exit-preview?guid=${guid}`} text="Forlad preview" icon="DoorExit" />
      </Grid>
    </>
  );

  return (
    <>
      <Floater position={{ left: '75%', right: '5%', bottom: '40%' }} visibleFrom="lg">
        <Paper>{PreviewActions}</Paper>
      </Floater>
      <Floater position={{ bottom: 100, right: 20 }} hiddenFrom="lg">
        <Button text="Preview" icon="Eye" onClick={() => setShowModal(!showModal)} />
        <Modal opened={showModal} onClose={() => setShowModal(false)}>
          {PreviewActions}
        </Modal>
      </Floater>
    </>
  );
};

export default Preview;
