import PageTop from '@/components/PageTop';
import { LayoutProps } from '.';
import StickyProgramAndSignup from '@/components/StickyProgramAndSignup';

export const InfoPageLayout = ({ children, properties, image }: LayoutProps) => {
  return (
    <>
      <PageTop image={image} properties={properties} />
      <section>{children}</section>
      <StickyProgramAndSignup
        isActive={properties?.isActive}
        program={properties?.programLink?.[0] as Record<string, any>}
        signup={properties?.signUpLink?.[0] as Record<string, any>}
      />
    </>
  );
};

export default InfoPageLayout;
