import { TImageProps } from '@ingeniorforeningen/figurine-core';
import { IApiMediaWithCropsResponseModel } from '@ingeniorforeningen/umbraco-client';
import { useRouter } from 'next/router';
import { PageTop as PageTopComponent, TPageTopProps } from '@ingeniorforeningen/figurine-cms';
import React from 'react';
import ComponentsStyles from './components.module.css';
import { toImage } from '@/lib/helpers';

type Props = {
  image: IApiMediaWithCropsResponseModel | undefined;
  properties: Record<string, unknown> | undefined;
};

const PageTop = ({ image, properties }: Props) => {
  if (image === undefined || properties === undefined) {
    return null;
  }

  const { locale } = useRouter();
  let pageTopImage: TImageProps | undefined = undefined;

  if (image && image.url !== undefined) {
    const focalpoint = image?.focalPoint
      ? (`${(image?.focalPoint?.left ?? 0) * 100}% ${
          (image?.focalPoint?.top ?? 0) * 100
        }%` as `${number}% ${number}%`)
      : undefined;

    pageTopImage = {
      ...(toImage(image, locale) as TImageProps),
      objectPosition: focalpoint,
    };
  }

  return (
    <PageTopComponent
      mt="xl"
      pt="xl"
      title={(properties?.header as string) || ''}
      subtitle={(properties?.title as string) || ''}
      teaser={(properties?.teaser as string) || ''}
      image={pageTopImage}
      variant="classic"
      className={ComponentsStyles.pageTop}
    />
  );
};

export default PageTop;
