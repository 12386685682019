import { TImageProps } from '@ingeniorforeningen/figurine-core';
import { IApiMediaWithCropsResponseModel } from '@ingeniorforeningen/umbraco-client';

export const removeTrailingSlash = (str: string): string => {
  if (str === '/') {
    return str;
  }

  if (str.endsWith('/')) {
    return str.slice(0, -1);
  }

  return str;
};

export const nextPathToUrl = (slug: string[]): string | undefined => {
  if (!slug?.length) {
    return undefined;
  }

  let relativePath = '/';
  if (slug) {
    // ['driving-it', 'tilmelding'] -> '/driving-it/tilmelding/' (syntax of relativeurl)
    relativePath += `${[...slug].join('/')}/`;
  }

  return relativePath;
};

export const urlToNextPath = (url: string, locale: string): string[] =>
  url?.split('/').filter((e) => e && e !== locale) || '/';

export const ensureAbsoluteContentUrl = (url?: string): string => {
  const umbracoUrl = process.env.NEXT_PUBLIC_UMBRACO_API_URL || '';

  if (!url) {
    return umbracoUrl;
  }

  if (url.startsWith('/')) {
    return `${umbracoUrl}${url}`;
  }

  return url;
};

export const toImage = (
  image: IApiMediaWithCropsResponseModel | undefined,
  locale: string | undefined,
): TImageProps | undefined => {
  if (image && image.url !== undefined) {
    return {
      polymorphicProps: { fill: true, priority: true },
      alt: locale === 'en' ? image.properties?.altText_en : image.properties?.altText_da,
      src: image.url,
    } as TImageProps;
  }
  return undefined;
};
