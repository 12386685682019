import { Hero } from '@ingeniorforeningen/figurine-cms';
import React from 'react';
import { LayoutProps } from '.';
import StickyProgramAndSignup from '@/components/StickyProgramAndSignup';
import { toImage } from '@/lib/helpers';

const FrontPageLayout = (props: LayoutProps) => {
  const { properties, children } = props;
  return (
    <>
      <Hero
        data={props}
        image={toImage(props.image, 'da')}
        variant="event"
        style={{ height: '90vh' }}
        mb="xl"
      />
      <section>{children}</section>
      <StickyProgramAndSignup
        isActive={properties?.isActive}
        program={properties?.programLink?.[0] as Record<string, any>}
        signup={properties?.signUpLink?.[0] as Record<string, any>}
        heroPresent
      />
    </>
  );
};

export default FrontPageLayout;
