//This is the image loader we will use to load images from the CMS.
//Next comes with a width that matches the screen size.
//Quality and format parameters are fixed values that are alwas appended to the image URL.
export default function imagesharpImageLoader({ src, width, quality }) {
  const cleanSrc = src.split('?')[0];
  let url = `${cleanSrc }?width=${width}&quality=${quality || 75}`;
  if (!src.includes('.svg')) {
    url += `&format=webp`;
  }
  return url;
}
